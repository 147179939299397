import { useState, useEffect, useContext } from "react";
import BackofficeIcon from "../ions/BackofficeIcon";

import { FaHome, FaHandshake, FaFileContract, FaHandHoldingUsd, FaUsers } from "react-icons/fa"
import { BiCog } from "react-icons/bi"
import { PiPlantBold } from "react-icons/pi"

import UserImage from "../atoms/UserImage";
import SectionSidebar from "../molecules/SectionSidebar";
import { AuthContext } from "../../services/Auth/AuthContext";
import NewTooltip from "../atoms/NewTooltip";

export const BATCH_ANALYSIS_LIST = "/analysis/batch-analysis";

const Sidebar = () => {
	const [isSidebarOpen, setSidebarOpen] = useState(false);
	const [currentSidebarTab, setCurrentSidebarTab] = useState(null);
	const [isAdmin, setIsAdmin] = useState(false);
	const { user } = useContext(AuthContext);

	useEffect(() => {
		if (user)
			setIsAdmin(user.roles.includes("ADMIN"));

		const watchScreen = () => {
			if (window.innerWidth <= 1024) {
				setSidebarOpen(false);
			}
		};

		window.addEventListener("resize", watchScreen);

		return () => {
			window.removeEventListener("resize", watchScreen);
		};
	}, [useContext]);

	const toggleSidebar = (tab: any) => {
		if (isSidebarOpen && currentSidebarTab === tab) {
			setSidebarOpen(false);
		} else {
			setSidebarOpen(true);
			setCurrentSidebarTab(tab);
		}
	};

	const toggleUserMenu = () => {
		if (isSidebarOpen) setSidebarOpen(false);
		else {
			toggleSidebar("tabUser");
		}
	};

	return (
		<aside className="flex flex-shrink-0 transition-all h-screen">
			{isSidebarOpen && (
				<div
					onClick={() => setSidebarOpen(false)}
					className="fixed inset-0 z-10 bg-black bg-opacity-50 lg:hidden"
				></div>
			)}
			{isSidebarOpen && <div className="fixed inset-y-0 z-10 w-16 bg-white"></div>}

			<nav
				aria-label="Options"
				className={`z-20 flex-col items-center flex-shrink-0 hidden w-16 py-4 bg-slate-100 border-r-2 shadow-md sm:flex border-black-100`}
			>
				<BackofficeIcon />

				<div className="flex flex-col items-center flex-1 p-2 space-y-4">

					<a href={`${process.env.REACT_APP_BACKOFFICE_URL}/home`}
						className="p-2 transition-colors rounded-lg shadow-md hover:bg-blue-400 hover:text-white focus:outline-none focus:ring focus:ring-primary focus:ring-offset-white focus:ring-offset-2"
					>
						{FaHome && <FaHome className="w-5 h-5" />}
					</a>


					<NewTooltip content="Parceiras" alignment="right">
						<button
							id="partner-menu-button"
							onClick={() => toggleSidebar("linksTab")}
							className={`p-2 transition-colors rounded-lg shadow-md hover:bg-blue-400 hover:text-white focus:outline-none focus:ring focus:ring-primary focus:ring-offset-white focus:ring-offset-2 ${isSidebarOpen && currentSidebarTab === "linksTab" ? "text-white bg-blue-800" : "text-gray-500 bg-white"
								}`}
						>
							{FaHandshake && <FaHandshake className="w-5 h-5" />}
						</button>
					</NewTooltip>


					<a href={`${process.env.REACT_APP_BACKOFFICE_URL}/croptypes/all`}
						className="p-2 transition-colors rounded-lg shadow-md hover:bg-blue-400 hover:text-white focus:outline-none focus:ring focus:ring-primary focus:ring-offset-white focus:ring-offset-2"
					>
						{PiPlantBold && <PiPlantBold className="w-5 h-5" />}
					</a>

					<a href={`${process.env.REACT_APP_BACKOFFICE_URL}/contract-templates/all`}
						className="p-2 transition-colors rounded-lg shadow-md hover:bg-blue-400 hover:text-white focus:outline-none focus:ring focus:ring-primary focus:ring-offset-white focus:ring-offset-2"
						title="Modelos de contrato"
					>
						{FaFileContract && <FaFileContract className="w-5 h-5" />}
					</a>

					<NewTooltip content="Fila de pedidos" alignment="right">
						<button
							id="referral-menu-button"
							onClick={() => toggleSidebar("tabReferrals")}
							className={`p-2 transition-colors rounded-lg shadow-md hover:bg-blue-400 hover:text-white focus:outline-none focus:ring focus:ring-primary focus:ring-offset-white focus:ring-offset-2 ${isSidebarOpen && currentSidebarTab === "tabReferrals" ? "text-white bg-blue-800" : "text-gray-500 bg-white"
								}`}
						>
							{FaHandHoldingUsd && <FaHandHoldingUsd className="w-5 h-5" />}
						</button>
					</NewTooltip>

					<NewTooltip content="Empréstimos" alignment="right">
						<button
							id="loan-menu-button"
							onClick={() => toggleSidebar("tabLoan")}
							className={`p-2 transition-colors rounded-lg shadow-md hover:bg-blue-400 hover:text-white focus:outline-none focus:ring focus:ring-primary focus:ring-offset-white focus:ring-offset-2 ${isSidebarOpen && currentSidebarTab === "tabLoan" ? "text-white bg-blue-800" : "text-gray-500 bg-white"
								}`}
						>
							{FaHandshake && <FaHandshake className="w-5 h-5" />}
						</button>
					</NewTooltip>

					<NewTooltip content="Comercial" alignment="right">
						<button
							id="comercial-menu-button"
							onClick={() => toggleSidebar("tabCommercial")}
							className={`p-2 transition-colors rounded-lg shadow-md hover:bg-blue-400 hover:text-white focus:outline-none focus:ring focus:ring-primary focus:ring-offset-white focus:ring-offset-2 ${isSidebarOpen && currentSidebarTab === "tabCommercial" ? "text-white bg-blue-800" : "text-gray-500 bg-white"
								}`}
						>
							{FaUsers && <FaUsers className="w-5 h-5" />}
						</button>
					</NewTooltip>
				</div>

				<div className="flex flex-col items-center flex-1 p-2 space-y-4 flex-col-reverse">
					{isAdmin ?
						<NewTooltip content="Configurações gerais" alignment="right">
							<button
								data-tooltip-target="tooltip-generic"
								onClick={() => toggleSidebar("tabGeneric")}
								className={`p-2 transition-colors rounded-lg shadow-md hover:bg-blue-400 hover:text-white focus:outline-none focus:ring focus:ring-primary focus:ring-offset-white focus:ring-offset-2 ${isSidebarOpen && currentSidebarTab === "tabGeneric" ? "text-white bg-blue-800" : "text-gray-500 bg-white"
									}`}
							>
								{BiCog && <BiCog className="w-5 h-5" />}
							</button>
						</NewTooltip>
						: null}
				</div>

				<UserImage
					imageUrl="/no-user-image.jpeg"
					label="No User Image"
					onIconClick={toggleUserMenu}
				/>
			</nav>

			<div
				className={`${isSidebarOpen ? "fixed inset-y-0 left-0 z-10 flex-shrink-0 w-[260px] bg-white" : "hidden"
					} border-r-2 shadow-lg sm:left-16 border-primary-100 sm:w-64 lg:static lg:w-[260px]`}
			>
				<SectionSidebar
					sectionMenuItems={[
						{
							title: "Parceiras",
							menuItems: [
								{ href: `${process.env.REACT_APP_BACKOFFICE_URL}/guarantor/all`, label: 'Parceiras Solidárias', external: true, key: "menu-guarantor-all" },
								{ href: `${process.env.REACT_APP_BACKOFFICE_URL}/partners/all`, label: 'Parceiras', external: true, key: "menu-partners-all" },
								{ href: `${process.env.REACT_APP_BACKOFFICE_URL}/partner/new`, label: 'Cadastrar revenda', external: true, key: "menu-partners-new" },
								{ href: `${process.env.REACT_APP_BACKOFFICE_URL}/partnerUsers/all`, label: 'Usuários das revendas', external: true, key: "menu-partnerusers-all" }
							]
						}]}
					onCloseMenu={() => setSidebarOpen(false)}
					tabName="linksTab"
					currentSidebarTab={currentSidebarTab}
				/>

				<SectionSidebar
					onCloseMenu={() => setSidebarOpen(false)}
					sectionMenuItems={[
						{
							title: "Fila de pedidos",
							menuItems: [
								{ href: `${process.env.REACT_APP_BACKOFFICE_URL}/referral/analysis/all?sort=createdAt,DESC`, label: 'Análise', external: true, key: "menu-referral-analysis-all" },
								{ href: '/referrals/loan-order-analysis/0/100/referral.id/ASC', label: 'Lista de pedidos', key: "menu-referral-all" },
								{ href: `${BATCH_ANALYSIS_LIST}/0/40/createdAt/DESC`, label: 'Subir pedidos em lote', key: "menu-referral-upload" },
								{ href: `${process.env.REACT_APP_BACKOFFICE_URL}/referrals/register`, label: 'Registrar solicitações por CPF', external: true, key: "menu-referral-register" }
							]
						},
						{
							title: "Pré-análise",
							menuItems:
								[
									{ href: '/referrals/partner-analysis/0/100/referral.id/ASC', label: 'Lista de Pedidos - Revenda', key: "menu-referral-partners-analysis" },
									{ href: '/referrals/partner-analyzed/0/100/referral.id/ASC', label: 'Pedidos Analisados - Revenda', key: "menu-referral-partners-analyzed" },
									{ href: '/referrals/reasons/0/20/description/ASC/d=;t=PRE_ANALYSIS;', label: 'Motivos da pré-análise', key: "menu-referral-reason" }
								]
						},
						{
							title: "Pedido de empréstimo",
							menuItems:
								[
									{ href: '/referrals/farmer-analysis/0/100/referral.id/ASC', label: 'Lista de Pedidos - Produtor', key: "menu-referral-farmer-analysis", visible: process.env.REACT_APP_FARMER_ORDER_LIST_ACCESSIBLE == 'true' },
									{ href: '/referrals/reasons/0/20/description/ASC/d=;t=LOAN_ORDER;', label: 'Motivos', key: "menu-loan-order-reason" }
								]
						}
					]}
					tabName="tabReferrals"
					currentSidebarTab={currentSidebarTab}
					showConfigMenu={true}
					urlConfigMenu="/referrals/configuration"
				/>

				<SectionSidebar
					onCloseMenu={() => setSidebarOpen(false)}
					sectionMenuItems={[
						{
							title: "Empréstimos",
							menuItems: [
								{ href: `${process.env.REACT_APP_BACKOFFICE_URL}/loans/management`, label: 'Acompanhamento dos contratos', external: true, key: "menu-loans-management" },
								{ href: `${process.env.REACT_APP_BACKOFFICE_URL}/loans/all`, label: 'Lista de empréstimos', external: true, key: "menu-loans-all" },
								{ href: `${process.env.REACT_APP_BACKOFFICE_URL}/loans/partial-payment`, label: 'Lista de adiantamentos parciais', external: true, key: "menu-loans-partial-payment" }
							]
						}
					]}
					tabName="tabLoan"
					currentSidebarTab={currentSidebarTab}
				/>

				<SectionSidebar
					onCloseMenu={() => setSidebarOpen(false)}
					sectionMenuItems={[
						{
							title: "Comercial",
							menuItems: [
								{ href: '/commercial/0/100/partner.id/ASC', label: 'Associação Parceira x Gerentes', key: "menu-commercial-management" }
							]
						}
					]}
					tabName="tabCommercial"
					currentSidebarTab={currentSidebarTab}
				/>

				<SectionSidebar
					onCloseMenu={() => setSidebarOpen(false)}
					sectionMenuItems={[
						{
							title: "Configurações",
							menuItems: [
								{ href: '/cities', label: 'Cidades', key: "menu-cities" },
								{ href: '/provinces', label: 'Estados', key: "menu-provinces" },
								{ href: '/feature-flags/referral/0/20/id/ASC', label: 'Features flags - Referral', key: "menu-features-referral" }
							]
						}
					]}
					tabName="tabGeneric"
					currentSidebarTab={currentSidebarTab}
				/>

				<SectionSidebar
					onCloseMenu={() => setSidebarOpen(false)}
					sectionMenuItems={[
						{
							title: "Usuário",
							menuItems: [
								{ href: '/change-password', label: 'Alterar senha', key: "menu-change-password" }
								// { href: '/change-image', label: 'Alterar imagem' },
								// { href: '/change-layout', label: 'Alterar layout' },
							]
						}
					]}
					tabName="tabUser"
					currentSidebarTab={currentSidebarTab}
				/>

			</div>
		</aside>
	);
};

export default Sidebar;
